<template>
  <div class="document_tabs_wrapper">
    <!-- <validation-observer ref="docModuleValidator"> -->
    <div>
      <div style="box-shadow:none;margin-bottom:1rem">
        <span style="font-size:1.286rem;font-weight:500;" v-for="(data,index) in userData.documentModule" :key="index">
          {{data.title}}{{index!=userData.documentModule.length-1?', ':''}}
        </span>
      </div>
    </div>
    <b-row class="match-height">
        <b-col md="3">
            <b-card class="doc_box_tabs_main">
                <b-row>
                    <b-col md="12">
                        <div class="doc_box">
                        <div class="green_bg round_bg">
                        <img src="@/assets/images/erflog/TotalApproved_Doc.png" alt="">
                        </div>
                        <div class="doc_text_info">
                        <span class="doc_no">{{getDocCount('Doc. Approved')>0 ? getDocCount('Doc. Approved')+'/'+docList.length : getDocCount('Doc. Approved')}}</span>
                        <span class="doc_title">Total Approved Doc.</span>
                        </div>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col md="3">
            <b-card>
                <b-row>
                    <b-col md="12">
                          <div class="doc_box">
                          <div class="blue_bg round_bg">
                        <img src="@/assets/images/erflog/TotalPending_Documents.png" alt="">
                          </div>
                          <div class="doc_text_info">
                        <span class="doc_no">{{getDocCount('Doc. Pending')>0 ? getDocCount('Doc. Pending')+'/'+docList.length : getDocCount('Doc. Pending')}}</span>
                        <span class="doc_title">Total Pending Doc.</span>
                        </div>
                          </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col md="3">
            <b-card>
                <b-row>
                    <b-col md="12">
                          <div class="doc_box">
                          <div class="orange_bg round_bg">
                        <img src="@/assets/images/erflog/TotalApproval_PendingDoc.png" alt="">
                          </div>
                          <div class="doc_text_info">
                        <span class="doc_no"> {{getDocCount('Doc. Approval Pending')>0 ? getDocCount('Doc. Approval Pending')+'/'+docList.length : getDocCount('Doc. Approval Pending')}}</span>
                        <span class="doc_title">Total Approval Pending Doc.</span>
                        </div>
                          </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
        <b-col md="3">
            <b-card>
                <b-row>
                    <b-col md="12">
                            <div class="doc_box">
                          <div class="red_bg round_bg">
                          <img src="@/assets/images/erflog/TotalRejected_Doc.png" alt="">
                          </div>
                          <div class="doc_text_info">
                            <span class="doc_no">{{getDocCount('Doc. Rejected')>0 ? getDocCount('Doc. Rejected')+'/'+docList.length : getDocCount('Doc. Rejected')}}</span>
                            <span class="doc_title">Total Rejected Doc.</span>
                        </div>
                            </div>
                    </b-col>
                    
                </b-row>
            </b-card>
        </b-col>
        <b-col md="12">
            <user-list-add-new
                :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
                :role-options="roleOptions"
                :plan-options="planOptions"
                @refetch-data="refetchData"
            />

            <!-- Filters -->
            <!-- <users-list-filters
                :role-filter.sync="roleFilter"
                :plan-filter.sync="planFilter"
                :status-filter.sync="statusFilter"
                :role-options="roleOptions"
                :plan-options="planOptions"
                :status-options="statusOptions"
            /> -->

            <!-- Filters -->
            <div
            style="margin-bottom:0px;border-bottom:1px solid #cfcfcf;"
            >
            <div class="" style="box-shadow:none">
                <h4 class="card-title m-0 px-0" style="border-bottom:none;">Filters</h4>

                <b-row>
                <!-- <b-col md="3">
                    <b-form-group label="Category">
                    <b-form-select
                        v-model="category"
                        :options="categoryOptions"
                        placeholder="Select"
                    />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Manage By">
                    <b-form-select
                        v-model="uploadBy"
                        :options="uploadByOptions"
                        placeholder="Select"
                    />
                    </b-form-group>
                </b-col> -->
                <b-col md="3">
                    <b-form-group label="Status">
                    <b-form-select
                        v-model="status"
                        :options="statusOptions"
                        placeholder="Select"
                    />
                    </b-form-group>
                </b-col>
                </b-row>
            </div>
            </div>
            
            <!-- Table Container Card -->
            <b-card
            no-body
            class="mb-0"
            >

            <div class="mt-2 mb-2">

                <!-- Table Top -->
                <b-row>

                <!-- Search -->
                <b-col
                    cols
                    md="2"            
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <div>
                    <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                    />
                    <!-- <b-button
                        variant="primary"
                        @click="isAddNewUserSidebarActive = true"
                    >
                        <span class="text-nowrap">Add User</span>
                    </b-button> -->
                    </div>
                </b-col>

                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="10"
                  class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
                >
                    <span class="text-danger danger_color_custom" style="margin-right: 1.5em;">
                      <b>Maximum upload per file size is 2.0 MB</b>
                    </span>
                    <b-button
                    variant="primary"
                    style="margin-right:1.5rem;"
                    @click.prevent="DownloadAllDocs()"
                    >
                    <span class="text-nowrap">Download All Doc.</span>
                    </b-button>
                    
                    <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                    />
                    <!-- <label>entries</label> -->
                </b-col>
                </b-row>

            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative student_doc_table"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                hover
                empty-text="No matching records found"
                :sort-by.sync="sortBy"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: No. -->
                <template #cell(no)="data">
                <div>
                    <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
                </div>
                </template>

                <!-- Column: Document Name -->
                <template #cell(document_name)="data">
                <div style="white-space: nowrap">
                    {{data.item.document_name.documentName}}
                    <b-badge
                    pill
                    variant="primary"
                    v-if="data.item.commonIn.length!=1"
                    v-b-tooltip.hover.top="getCommonNames(data.item.commonIn)"
                    >
                    <div style="white-space: nowrap">
                        {{data.item.commonIn.length}}
                    </div>
                    </b-badge>
                </div>
                </template>

                <!-- Column: Upload File -->
                <template #cell(upload_file)="data">
                
                <div style="white-space: nowrap;align-items:center;" class="d-flex" v-for="(img,index) in data.item.upload_file" :key="'uploadFile'+index">
                    <!-- {{data.item.status}} -->
                    <img src="@/assets/images/erflog/Lock.png" v-if="data.item.status.status.toLowerCase()=='doc. approved'" class="action_icon">
                    <span style="color:black;">{{img.text!='single'?img.text:''}}</span>
                    <img src="@/assets/images/erflog/UploadDoc.png" v-if="data.item.status.status.toLowerCase()=='doc. approved'" class="action_icon">
                    <img src="@/assets/images/erflog/PendingDoc.png" v-if="data.item.status.status.toLowerCase()=='doc. approval pending'" class="action_icon">
                    <div v-if="img.file=='' || data.item.status.status.toLowerCase()=='doc. rejected'">
                      <a v-if="!fileUploading" @click.prevent="selectedDocument={data:data.item,docUploadIndex:index},uploadFile(data)" style="text-decoration:underline;color:#0d4ea0">Upload</a>
                      <span v-else style="text-decoration:underline">Upload</span>
                    </div>
                    <small class="text-danger" style="display:block">{{ img.errorMsg }}</small>
                    <!-- <span v-if="img.file!=''">{{getNameWithoutId(img.file.imageName)}}</span> -->
                    <a target="_blank" :href="img.file.imageUrl" v-if="img.file!=''" ><img src="@/assets/images/erflog/view.png" title="View document" class="action_icon"></a>
                    <img src="@/assets/images/erflog/Edit.png" title="Edit document" v-if="!fileUploading && data.item.status.status.toLowerCase()!='doc. pending' && data.item.status.status.toLowerCase()!='doc. approved'" @click.prevent="selectedDocument={data:data.item,docUploadIndex:index},uploadFile(data)" class="action_icon">
                    <img src="@/assets/images/erflog/Edit.png" title="Edit document" v-if="fileUploading && data.item.status.status.toLowerCase()!='doc. pending' && data.item.status.status.toLowerCase()!='doc. approved'" class="action_icon">
                    <img src="@/assets/images/erflog/Close_Red.png" title="Remove document" v-if="data.item.status.status.toLowerCase()=='doc. rejected' || data.item.status.status.toLowerCase()=='doc. approved'" @click.prevent="removeDocumentFunction(data.item,index)" class="action_icon">
                    <b-spinner variant="primary" v-if="img.isSpinner" small/>
                </div>
                </template>

                <!-- Column: Status -->
                <template #cell(status)="data">
                  <div v-if="data.item.status!=''">
                    <b-badge
                        pill
                        :variant="data.item.status.variant"
                        v-if="data.item.status!=''"
                    >
                        <div style="white-space: nowrap">
                        <span>
                            {{data.item.status.status}}
                        </span>
                        </div>
                    </b-badge>
                    <img 
                      src="@/assets/images/erflog/info_icon.png"
                      alt="info"
                      v-if="data.item.status.status.toLowerCase()=='doc. rejected'"
                      v-b-tooltip.hover.top="getRejectReason(data.item.reject_reason)"
                      class="action_icon"
                    >
                  </div>
                  <div v-else>
                    <b-badge
                        pill
                        :variant="data.value.variant"
                    >
                        <div style="white-space: nowrap">
                        <span>
                          {{data.value.status}}
                        </span>
                        </div>
                    </b-badge>
                  </div>
                </template>
                <template #cell(Uploaded_Date)="data">
                  {{(data.item.uploaded_date && data.item.uploaded_date.seconds) ? displayFormatdate(data.item.uploaded_date.seconds) : '-'}}
                </template>

                <!-- Column: Action -->
                <template #cell(action)="data">
                <div style="white-space: nowrap" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                    <!-- <img src='@/assets/images/erflog/Download.png' @click.prevent="downloadFile(data.item.upload_file)" class="action_icon"/> -->
                    <img src='@/assets/images/erflog/Download.png' v-if="showDownloadIcon(data.item.upload_file)" @click.prevent="downloadFile(data.item.upload_file)" class="action_icon"/>
                    <span v-if="data.item.status.status=='Doc. Approval Pending'">
                    <img src='@/assets/images/erflog/Approved.png' @click.prevent="selectedDocument = data.item,$bvModal.show('modal-accept-document')" class="action_icon"/>
                    <img src='@/assets/images/erflog/Rejected.png' v-b-modal.modal-reject-document @click.prevent="selectedDocument=data.item" class="action_icon"/>
                    </span>
                </div>
                <div v-else>
                  --
                </div>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalUsers"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    >
                    <template #prev-text>
                        <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                        />
                    </template>
                    </b-pagination>
                </b-col>
                </b-row>
            </div>
            <input type="file" id="docFile1" v-show="false" @change="checkFile">
            </b-card>
        </b-col>
    </b-row>
    <!-- </validation-observer> -->
    
    <!--Accept Document Modal -->
    <b-modal
        id="modal-accept-document"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @cancel="selectedDocument=''"
        @ok="(e)=>{e.preventDefault();approveDocumentFunction(selectedDocument)}"
    >
    <div class="create_account_img">
    <img src="@/assets/images/erflog/Approve_Popup.png" alt="student">
    </div>
    <div style="text-align: center;" v-if="selectedDocument!=''">
        <label class="s_modal_title">Approve</label>
        <p style="">Are you sure you want to Approve?</p>
    </div>
    </b-modal>

    <!--Reject Document -->
    <b-modal
        id="modal-reject-document"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        title="Reject"
        :no-close-on-backdrop="true"
        @hidden="rejectReasonText='',rejectReason=[]"
        @ok="(e)=>{e.preventDefault();rejectDocumentFunction(e)}"
    >
    <div>
        <validation-observer ref="rejectDocumentValidator">
        <!-- form -->
        <b-form>
            <b-row>
                <!-- Remark -->
                <b-col md="12">
                    <validation-provider
                        #default="{ errors }"
                        name="remark option"
                        rules="required"
                    >
                        <b-form-checkbox-group
                            id="checkbox-group-1"
                            v-model="rejectReason"
                            :options="rejectOptions"
                            name="flavour-1"
                            md="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <!-- Remark Text -->
                <b-col md="12">
                    <validation-provider
                        #default="{ errors }"
                        name="remark"
                        rules=""
                    >
                        <b-form-group label="Remark">
                            <b-form-textarea
                                v-model="rejectReasonText"
                                no-resize
                                name="remark"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="rejectReasonText=rejectReasonText.trim()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                    </validation-provider>
                </b-col>
            </b-row>
        </b-form>
        </validation-observer>
    </div>
    </b-modal>
      <!-- <input type="file" id="myFile1" @change="editFile" v-show="false"> -->
  </div>
</template>

<script>

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as'
import moment from 'moment'

import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required, email,max,max_value,integer,min_value,numeric,min,alpha_spaces } from 'vee-validate/dist/rules';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,VBTooltip,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,BFormFile,
  BFormTextarea,BFormCheckboxGroup,BForm,BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useDocumentsList'
import userStoreModule from './userStoreModule'
import UsersListFilters from './UsersListFilters.vue'
import UserListAddNew from './UserListAddNew.vue'

import firebase from '@/utils/firebaseInit'
import firebaseTimeStamp from 'firebase'
import { dbCollections } from '@/utils/firebaseCollection'
import { uploadImageFile,removeImage } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ValidationProvider, ValidationObserver,
    UsersListFilters,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormSelect,
    BFormGroup,
    BFormFile,
    BFormTextarea,
    BFormCheckboxGroup,
    BForm,
    vSelect,
    BSpinner,
  },

  props:['userData'],

  data(){
    return{
      selectedUser:"",
      selectedDocument:'',

      fileUploading:false,

      //Doc Reject Values
      rejectReason:[],
      rejectOptions:['Image Poor Quality','Upload the Larger Size Document','Not the Correct Document','Other'],
      rejectReasonText:'',

      userDocuments:[],
      // university:null,
      // representative:null,
      // status:null,

      uploadByOptions:[
        { value: null, text: 'All' },
        { value: 'Yes', text: 'Student' },
        { value: 'No', text: 'Employee/Admin' },
      ],
      statusOptions:[
        { value: null, text: 'All' },
        { value: 'Doc. Pending', text: 'Doc. Pending' },
        { value: 'Doc. Approval Pending', text: 'Doc. Approval Pending' },
        { value: 'Doc. approved', text: 'Doc. Approved' },
        { value: 'Doc. Rejected', text: 'Doc. Rejected' },
      ],

      folderName:'Student Documents'
    }
  },
  created() {
    var self = this

    self.docId = self.$route.params.id

    if(!self.docId)
    {
      self.$router.push({name:'student-mgmt'})
    }

    db
      .collection(dbCollections.USERS)
      .doc(self.docId)
      .onSnapshot((doc)=>{
        self.userDocuments=[]
        self.userDocuments=doc.data().docFiles
      })
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      representativeOptions,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      category,
      categoryOptions,
      uploadBy,
      status,

      //My Values
      docId,
      docList,
      approveDocument,
      rejectDocument,
      removeDocument,
      appendUploadedFile,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      representativeOptions,
      refetchData,

      // Filter
      avatarText,

      category,
      categoryOptions,
      uploadBy,
      status,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      //My Values
      docId,
      docList,
      approveDocument,
      rejectDocument,
      removeDocument,
      appendUploadedFile,
    }
  },
  methods:{
    displayFormatdate(date)
    {
      return moment(date*1000).format('DD MMM YYYY hh:mm A')
    },
    // getDocumentsData()
    // {
    //   var self = this
    //   db
    //     .collection(dbCollections.USERS)
    //     .doc(self.$route.params.id)
    //     .get().then((doc)=>{
    //       // self.userDocuments=[]
    //       self.userDocuments=doc.data().docFiles
    //     })
    //     // .catch(error=>console.log(error))
    // },
    getRejectReason(reasons)
    {
      var reason = "";
      // console.log("Reject",reasons);
      reasons.reasons.forEach((val,index)=>{
        reason+=`${index>0?', ':''}${val}`;
      })
      if(reasons.rejectReasonRemark!='')
      {
        reason+=', '+reasons.rejectReasonRemark;
      }

      return reason;
    },
    showDownloadIcon(data)
    {
      var val = true

      data.forEach(imgs=>{
        if(imgs.file=='')
        { 
          val = false;
        }
      })

      return val;
    },
    getDocCount(str)
    {
      var self = this
      var count = 0

      if(self.docList && self.docList.length!=0)
      {
        self.docList.forEach(data=>{
          if(data.status.status.toLowerCase()==str.toLowerCase())
          {
            count++;
          }
        })
      }

      return count;
    },
    checkFileUploaded(id,img)
    {
      var name = ''
      this.userDocuments.forEach(data=>{
        data.docs.forEach(file=>{
          if(data.documentName.id == id && file.fileSide==img.text)
          {
            name = file
          }
        })
      })

      return name
    },
    getCommonNames(names)
    {
      var str = `This Document belongs to ${names.length} categories`

      names.forEach((data,index)=>{
        str+=` ${index!=0 && index!=names.length-1?', ':index==names.length-1?' and ':''}${data}`
      })

      return str
    },
    uploadFile(data){
      document.getElementById('docFile1').click();
    },
    // addFile(e,data)
    // {
    //   var files = e.target.files || e.dataTransfer.files;
    //   console.log("data",files,data)
    // },
    handleRowClick(data)
    {
      // console.log(data)
      //Show User Details
      this.$router.push({name:'student-information',params:{name:data.student_name,id:data.id}})
    },
    getNameWithoutId(imageName)
    {
      var self = this

      var ext = imageName.split('.')
        ext = ext[ext.length-1]
        var tmp = imageName.split('_')
        var name = ''

        tmp.forEach((vals,index)=>{
          if(index!=tmp.length-1 && index!=0 && index!=1)
          {
            name+='_'+vals.trim()
          }
        })
        name+='.'+ext
        // console.log("Name",`${self.userData.firstName}_${self.userData.lastName}${name}`)

      return `${self.userData.firstName}_${self.userData.lastName}${name}`
    },
    getNameWithoutId2(imageName,count)
    {
      var self = this

      var ext = imageName.split('.')
        ext = ext[ext.length-1]
        var tmp = imageName.split('_')
        var name = ''
        // console.log("name",tmp)
        // name = name.splice(name.length-1,1)

        tmp.forEach((vals,index)=>{
          if(index!=tmp.length-1 && index!=0 && index!=1)
          {
            name+='_'+vals.trim()
          }
        })

        if(count!=0)
        {
          name+=' ('+count+').'+ext
        }
        else
        {
          name+='.'+ext
        }

        // console.log("Name",`${self.userData.firstName}_${self.userData.lastName}${name}`)

      return `${self.userData.firstName}_${self.userData.lastName}${name}`
    },
    downloadFile(item) {
      // var URL = url.split('?alt')
      var self = this


      item.forEach(data=>{
        
        var name = self.getNameWithoutId(data.file.imageName);

        self.$axios({
            url: data.file.imageUrl,
            method: 'GET',
            responseType: 'blob',
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
  
            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
  
            fileLink.click();
        })
        .catch(error=>{
            console.log(error)
        })
      })

    },

    DownloadAllDocs(){
      var self = this
      // console.log("Download All",self.docList)

      // self.docList.forEach(data=>{
      //   // data.fileType.forEach(data2=>{
      //     self.downloadFile(data.fileType)
      //   // })
      // })
      var zip = new JSZip();
      var count = 0;
      var zipFilename = `${self.userData.firstName.trim()}_${self.userData.lastName.trim()}_documents.zip`;

      // var insoFiles=[]
      // self.tableData[self.currentPage][index].inspoPicFiles.forEach(data=>{
      //     insoFiles.push(data)
      // })

      var finalArr = []

      self.docList.forEach(data=>{
        var count = 0
        data.fileType.forEach(data2=>{
          if(data2.file!=undefined && data2.file!="")
          {
            var imgName = self.getNameWithoutId2(data2.file.imageName,count).replaceAll('/',"_")
            finalArr.push({imageUrl:data2.file.imageUrl,imageName:imgName})
          }
          count++ 
        })
      })

      finalArr.forEach(function(url){
      var filename = url.imageName;
      // console.log("url")
      // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url.imageUrl, function (err, data) {
              if(err) {
                  throw err; // or handle the error
              }
              zip.file(filename, data, {binary:true});
              count++;
              if (count == finalArr.length) {
              zip.generateAsync({type:'blob'}).then(function(content) {
                  saveAs(content, zipFilename);
              });
              }
          });
      });
    },

    //Check if is valid(image) file and upload
    checkFile(e)
    {
      var self = this
      var files = e.target.files || e.dataTransfer.files;
      var currentUsers = JSON.parse(localStorage.getItem('currentUser'));
      if(files.length!=0)
      {
        this.previewImage=[]
        this.imageFile=[]
      }
      else
      {
        return;
      }
      var isImage=true
      var selectedDocument = self.selectedDocument
      // this.attachmentImages=[]
      // this.attachments=[]
      files.forEach(data=>{
          var docExt = data.name.split('.')
          if(data.type.split('/')[1]!='jpeg' && data.type.split('/')[1]!='png' && data.type.split('/')[1]!='pdf' && docExt[docExt.length-1]!='doc' && docExt[docExt.length-1]!='docx')
          {
            // this.attachmentImages=[]
            isImage=false
            selectedDocument.data.upload_file[selectedDocument.docUploadIndex].errorMsg="Please select an image file."
          }
          else if(data.size>2*1024*1024)
          {
            isImage=false
            selectedDocument.data.upload_file[selectedDocument.docUploadIndex].errorMsg="File size too big."
          }
      })
      
      if(isImage){
        self.fileUploading=true
        isImage=true
        selectedDocument.data.upload_file[selectedDocument.docUploadIndex].errorMsg=""
        selectedDocument.data.upload_file[selectedDocument.docUploadIndex].isSpinner=true
        //Upload File 
        let pObject
        //On res upload doc File to user
        if(self.selectedDocument)
        var UDIndex=0
        var found = false
        var status = {status:'Doc. pending',variant:'primary'}
        self.userDocuments.forEach((data,ind)=>{
          if(data.id == selectedDocument.data.id)
          {
            found = true
            UDIndex=ind
            self.userDocuments[UDIndex].docs.forEach((file,delIndex)=>{
              if(file.fileSide==selectedDocument.data.upload_file[selectedDocument.docUploadIndex].text)
              {
                if(file.imageUrl)
                {
                  removeImage(self.folderName,file.imageName,res=>{
                    pObject = {...self.userDocuments[ind]}
                    self.userDocuments[UDIndex].docs.splice(delIndex,1)
                  })
                }
              }
            })
          }
        })

        var ext = files[0].name.split('.')


        ext = ext[ext.length-1]


        var imgName = `${self.userData.firstName.trim()}_${self.userData.lastName.trim()}_${selectedDocument.data.document_name.documentName}.${ext}`

        uploadImageFile(self.folderName,imgName,files[0],res=>{
          
          this.$axios.get(dbCollections.APIURL + 'api/v1/getTime')
          .then(data => {
            let currentUtcString = data?.data?.utc_datetime ? data?.data?.utc_datetime : new Date().toISOString();
            if(found)
            {
              let object = {
                fileSide:selectedDocument.data.upload_file[selectedDocument.docUploadIndex].text,
                imageUrl:res.imageUrl,
                imageName:res.imageName,
                uploadDate: new Date(currentUtcString),
                // imageUrl:'res.imageUrl',
                // imageName:'res.imageName',
              }
              // let date = new Date();
              // object.uploadDate.push(date)
              self.userDocuments[UDIndex].docs.push(object)
            }
            else
            {
              self.userDocuments.push({
                categoryName:selectedDocument.data.category,
                documentName:selectedDocument.data.document_name,
                id:selectedDocument.data.id,
                docs:[{
                  fileSide:selectedDocument.data.upload_file[selectedDocument.docUploadIndex].text,
                  imageUrl:res.imageUrl,
                  imageName:res.imageName,
                  uploadDate: new Date(currentUtcString),
                  // imageUrl:'res.imageUrl',
                  // imageName:'res.imageName',
                }],
                status:status
              })
              UDIndex = self.userDocuments.length-1
            }
  
            if(self.userDocuments[UDIndex].docs.length == selectedDocument.data.upload_file.length)
            {
              self.userDocuments[UDIndex].status = {status:'Doc. Approval Pending',variant:'warning'}
            }
  
            db
              .collection(dbCollections.USERS)
              .doc(self.docId)
              .update({
                updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                docFiles:self.userDocuments
              })
              .then(()=>{
                self.appendUploadedFile(self.userDocuments[UDIndex],res2=>{
                })
                let notificationObj = {
                    title:"Document Upload",
                    type: 'documentUploadadmin',
                    userId: self.docId,
                    currentUser:firebase.auth().currentUser.uid
                }
                if(found) {
                  notificationObj.message = `${currentUsers.fullName} has reuploaded document in "${selectedDocument.data.document_name.documentName}" category`;
                } else {
                  notificationObj.message = `${currentUsers.fullName} has uploaded document in "${selectedDocument.data.document_name.documentName}" category`;
                }
                this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then(()=>{
                }).catch((error)=>{
                    console.error(error);
                });
                self.$root.$emit('showToastMessage','Document uploaded successfully.','success')
                self.previewImage=[]
                self.imageFile=[]
                document.getElementById("docFile1").value=""
                setTimeout(()=>{
                  selectedDocument.data.upload_file[selectedDocument.docUploadIndex].isSpinner=false
                  self.selectedDocument=''
                  self.fileUploading=false
                },1500)
              })
              .catch(error=>{
                selectedDocument.data.upload_file[selectedDocument.docUploadIndex].isSpinner=false
                self.selectedDocument=''
                self.fileUploading=false
                self.$root.$emit('showToastMessage',error.message,'danger')
                console.log(error)
              })
          }).catch(() => {
            let notificationObj = {
              title:"Document Upload",
              type: 'documentUploadadmin',
              userId: self.docId,
              currentUser:firebase.auth().currentUser.uid
            }
            let currentUtcString = new Date().toISOString();
            if(found)
            {
              let object = {
                fileSide:selectedDocument.data.upload_file[selectedDocument.docUploadIndex].text,
                imageUrl:res.imageUrl,
                imageName:res.imageName,
                uploadDate: new Date(currentUtcString),
                // imageUrl:'res.imageUrl',
                // imageName:'res.imageName',
              }
              // let date = new Date();
              // object.uploadDate.push(date)
              self.userDocuments[UDIndex].docs.push(object)
            }
            else
            {
              self.userDocuments.push({
                categoryName:selectedDocument.data.category,
                documentName:selectedDocument.data.document_name,
                id:selectedDocument.data.id,
                docs:[{
                  fileSide:selectedDocument.data.upload_file[selectedDocument.docUploadIndex].text,
                  imageUrl:res.imageUrl,
                  imageName:res.imageName,
                  uploadDate: new Date(currentUtcString),
                  // imageUrl:'res.imageUrl',
                  // imageName:'res.imageName',
                }],
                status:status
              })
              UDIndex = self.userDocuments.length-1
            }
  
            if(self.userDocuments[UDIndex].docs.length == selectedDocument.data.upload_file.length)
            {
              self.userDocuments[UDIndex].status = {status:'Doc. Approval Pending',variant:'warning'}
            }
  
            db
              .collection(dbCollections.USERS)
              .doc(self.docId)
              .update({
                updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                docFiles:self.userDocuments
              })
              .then(()=>{
                self.appendUploadedFile(self.userDocuments[UDIndex],()=>{
                })
                if(found) {
                  notificationObj.message = `${currentUsers.fullName} has reuploaded document in "${selectedDocument.data.document_name.documentName}" category`;
                } else {
                  notificationObj.message = `${currentUsers.fullName} has uploaded document in "${selectedDocument.data.document_name.documentName}" category`;
                }
                this.$axios.post(dbCollections.APIURL+'api/v1/uploadDocument',notificationObj).then((resp)=>{
                }).catch((error)=>{
                    console.error(error);
                });
                self.$root.$emit('showToastMessage','Document uploaded successfully.','success')
                self.previewImage=[]
                self.imageFile=[]
                document.getElementById("docFile1").value=""
                setTimeout(()=>{
                  selectedDocument.data.upload_file[selectedDocument.docUploadIndex].isSpinner=false
                  self.selectedDocument=''
                  self.fileUploading=false
                },1500)
              })
              .catch(error=>{
                selectedDocument.data.upload_file[selectedDocument.docUploadIndex].isSpinner=false
                self.selectedDocument=''
                self.fileUploading=false
                self.$root.$emit('showToastMessage',error.message,'danger')
                console.log(error)
              })
          })
        })
      }
      else
      { 
        this.previewImage=[]
        this.imageFile=[]
        document.getElementById("docFile1").value=""
        self.selectedDocument=''
      }
    },

    removeDocumentFunction(item,index)
    {
      var self = this

      var imageName = ''

      self.userDocuments.forEach((data,fileIndex)=>{
        if(data.documentName.id == item.id)
        {
          data.docs.forEach((file,ind)=>{
            if(file.fileSide==item.upload_file[index].text)
            {
              imageName = file.imageName
              data.status={status:'Doc. Pending',variant:'primary'}
              // self.removeDocument(data,ind,res=>{
                // console.log(res,"Update Table")
                data.docs.splice(ind, 1)
                if(imageName!='')
                {
                  removeImage(self.folderName,imageName, res=>{
                  })
                  db
                    .collection(dbCollections.USERS)
                    .doc(self.docId)
                    .update({
                      updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                      docFiles:self.userDocuments
                    })
                    .then(()=>{
                      self.$root.$emit('showToastMessage','Document removed successfully.','success')
                    })
                    .catch(error=>{
                      self.$root.$emit('showToastMessage',error.message,'danger')
                      console.log(error)
                    })
                }
              // })
            }
          })
        }
      })
    },

    approveDocumentFunction(item)
    {
      var self = this

      // self.approveDocument(item,res=>{
      //   console.log('res',res)
        self.userDocuments.forEach((data)=>{
          if(data.id == item.id)
          {
            data.status={status:'Doc. Approved',variant:"success"}
          }
        })

        db
          .collection(dbCollections.USERS)
          .doc(self.docId)
          .update({
            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
            docFiles:self.userDocuments
          })
          .then(()=>{
            self.$root.$emit('showToastMessage','Document approved successfully.','success')
            let notificationObj = {
                title: `Document Approved`,
                message: `Your document "${item.document_name.documentName}" is approved`,
                type: 'documentApprove',
                userId: self.docId,
                currentUser:firebase.auth().currentUser.uid
            }
            this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
            }).catch((error)=>{
                console.error(error);
            })
            self.$bvModal.hide('modal-accept-document')
          })
          .catch(error=>{
            self.$root.$emit('showToastMessage',error.message,'danger')
            console.log(error)
          })
      // })
    },

    rejectDocumentFunction(e)
    {
      var self = this
      var currentUsers = JSON.parse(localStorage.getItem('currentUser'));
      let documentName = ""
      self.$refs.rejectDocumentValidator.validate()
      .then((success)=>{
        if(success)
        {
          var item = self.selectedDocument
          self.selectedDocument=''

          self.userDocuments.forEach((data)=>{
            if(data.id == item.id)
            {
              documentName = data.documentName.documentName
              data.rejectReason={reasons:self.rejectReason,rejectReasonRemark:self.rejectReasonText}
              data.status={status:'Doc. Rejected',variant:"danger"}
            }
          })

          item.reject_reason={reasons:self.rejectReason,rejectReasonRemark:self.rejectReasonText}


          // self.rejectDocument(item,self.rejectReason,self.rejectReasonText,res=>{
          //   console.log(res,"Doc Rejected!")
            db
              .collection(dbCollections.USERS)
              .doc(self.docId)
              .update({
                updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                docFiles:self.userDocuments
              })
              .then(()=>{
                self.$root.$emit('showToastMessage','Document rejected successfully.','success')
                let notificationObj = {
                    title: `Document Rejected`,
                    message: `Your document "${documentName}" is rejected`,
                    type: 'documentReject',
                    userId: self.docId,
                    currentUser:firebase.auth().currentUser.uid
                }
                this.$axios.post(dbCollections.APIURL+'api/v1/transectionNotification',notificationObj).then(()=>{
                }).catch((error)=>{
                    console.error(error);
                })
                self.$bvModal.hide('modal-reject-document')
              })
              .catch(error=>{
                self.$root.$emit('showToastMessage',error.message,'danger')
                console.log(error)
              })
          // })
        }
        else
        {
          e.preventDefault()
        }
      })
    },
  }
}
</script>
<style scoped>
.card-bottom-border{
    border-bottom: 1px solid #cfcfcf;
    border-radius: unset;
    margin-top: 15px;
    padding-bottom: 15px;
}
.card-title-border-bottom{
    border-top:1px solid #cfcfcf;
    padding-top:1.5rem;
}
.imageSpinner{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.edit_download_icons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit_download_icons img {
    margin: 0px 5px;
}
.edit_download_icons img:last-child {
    background-color: #fff;
    height: 23px;
    width: 23px;
    padding: 5px;
    border-radius: 3px;
}

.card-body{
    box-shadow: 3px 3px 20px #cfcfcf;
    border-radius: 5px;
}


.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.position-relative.student_doc_table{padding:0px!important}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>