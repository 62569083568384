<template>
    <div>
        <div>
            <div style="margin-bottom:50px;"> 
                <div class="coloumn">
                    <div class="col-md-6 mb-3">
                        <div class="upload_wrapper">
                            <div class="upload_save">
                                <b-form-group label="Rough SOP">
                                </b-form-group>
                            </div>
                        </div>
                        <div v-if="attachemntArray && attachemntArray.length > 0">
                            <div v-for="(files,index) in attachemntArray" :key="index" class="d-flex align-items-center">
                                <span class="fileUploadColor deleteBtn">
                                    {{files.file}}
                                </span>
                                <span class="delete-pdf" v-if="!isSpinner">
                                    <!-- <img style="cursor:pointer;" @click="downloadDocument(files)" src="../../../assets/images/erflog/view.png" /> -->
                                    <img style="cursor:pointer;" @click="downloadDocument(files,'rough')" src="../../../assets/images/icons/material-symbols_download.png" />
                                </span>
                                <span v-if="!isSpinner">
                                    <img style="cursor:pointer;" @click="openModel('rough',files)" src="../../../assets/images/erflog/Delete.png" />
                                </span>
                                <span class="delete-pdf">
                                    <b-spinner variant="primary" v-if="isSpinner" large class="spinner-border-data"/>
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            No Record Found
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="upload_wrapper mb-1">
                            <div class="upload_save">
                                <b-form-group label="Upload SOP Here">
                                    <b-button v-b-modal.add-sop-modal variant="btn btn-icon btn-outline-primary" class="mr-1">Add SOP</b-button>
                                    <button class="btn btn-icon btn-primary" @click="fileUploadFunctionHandler('sop')" :disabled="isSpinner2">Save</button>
                                </b-form-group>
                            </div>
                        </div>
                        <b-table class="company_document_table position-relative padding-custom" striped hover :items="commonArrayFOrSOP" :fields="sopFields" v-if="commonArrayFOrSOP !== null && commonArrayFOrSOP.length">
                            <template #cell(title)="row">
                                <span>{{ row.item.title }}</span>
                            </template>
                            <template #cell(file)="row">
                                <span>{{ row.item.file }}</span>
                            </template>
                            <template #cell(actions)="row">
                                <span class="delete-pdf" v-if="!spinnerStatus[row.item.id]">
                                    <img style="cursor:pointer;" @click="downloadDocument(row.item, 'sop')" src="../../../assets/images/icons/material-symbols_download.png" />
                                </span>
                                <span v-if="!spinnerStatus[row.item.id]">
                                    <img style="cursor:pointer;" @click="openModel('sop', row.item)" src="../../../assets/images/erflog/Delete.png" />
                                </span>
                                <span class="delete-pdf">
                                    <b-spinner v-if="spinnerStatus[row.item.id]" variant="primary" large class="spinner-border-data"/>
                                </span>
                            </template>
                        </b-table>
                        <div v-else>
                            No Record Found
                        </div>
                    </div>
                </div>
            </div>
            <b-modal
                id="modal-removeDoc"
                ok-title="Yes"
                cancel-title="No"
                centered
                @ok="removeDoc"
                @cancel="cancelModelHandler"
                hide-header
            >
            <h3>Are you sure you want to delete file?</h3>
            </b-modal>
            <b-modal
                id="add-sop-modal"
                title="Upload SOP"
                centered
                size="lg"
                :no-close-on-backdrop="true"
                @hidden="resetData"
                @ok="(e)=>{e.preventDefault();handleFileUpload(e);}" 
                :cancel-disabled="modalLoader"
                :ok-disabled="modalLoader"
                ok-title="Add"
                cancel-title="Cancel"
                >
                <validation-observer ref="sopForm">
                    <b-form @submit.prevent="handleFileUpload">
                        <b-spinner v-if="modalLoader" variant="primary" large class="pageLoadSpinners" />
                        <b-row>
                            <b-col md="6">
                                <b-form-group label="Title">
                                    <validation-provider rules="required" name="title" v-slot="{ errors }">
                                        <b-form-input trim v-model="sopTitle" maxLength="50" :state="errors.length > 0 ? false : null" :disabled="modalLoader" placeholder="Enter Title" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="Upload File" class="sop_modal_filetype">
                                    <validation-provider rules="required" name="file" v-slot="{ errors }">
                                        <b-form-file v-model="sopFile" id="uploadFileArray1" :state="sopFile ? true : null" :disabled="modalLoader" placeholder="Upload Document"/>
                                        <span class="fileUploadColor deleteBtn" v-if="sopFile !== null && sopFile.name !== null">
                                            {{sopFile.name}}
                                        </span>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-modal>
        </div>
    </div>
</template>
<script>
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries';
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import { dbCollections } from '@/utils/firebaseCollection.js';
import firebase from "firebase";
import firebaseDatabase from "@/utils/firebaseInit.js";
const db = firebaseDatabase.firestore();
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules';
extend('required',required)
import axios from 'axios';
import {
  BFormGroup,BFormFile,BSpinner,BModal,
  BFormInput,
  BTable,
  BButton,
  BRow,
  BForm,
  BCol
} from 'bootstrap-vue';
export default {
    name: "sop-upload",
    data() {
        return {
            attachemntArray:[],
            attachemntArraySOP:[],
            commonArrayFOr:[],
            commonArrayFOrSOP:[],
            fileType: '', 
            fileId:'',
            fileNameRoughSop: '', 
            fromWhere:'',
            dataFromDB:null,
            isSpinner:false,
            isSpinner2:false,
            subscribeSnap: null,
            sopFile: [],
            sopFields: [
                { key: 'title', label: 'Title' },
                { key: 'file', label: 'File' },
                { key: 'actions', label: 'Actions', class: 'text-center' }
            ],
            modalLoader: false,
            sopTitle: '',
            spinnerStatus:{}
        }
    },
    components: {
        BFormGroup,
        BFormFile,
        BSpinner,
        BModal,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
        BTable,
        BButton,
        BRow,
        BForm,
        BCol
    },
    beforeDestroy(){
        if(this.subscribeSnap !== null){
            this.subscribeSnap();
        }
    },
    created(){
        this.subscribeSnap = db.collection(dbCollections.USERS)
            .doc(this.$route.params.id)
            .onSnapshot((docSnapshot) => {
                if(!docSnapshot.exists) {
                    this.commonArrayFOrSOP = [];
                    this.attachemntArray = [];
                    return;
                }

                const docData = docSnapshot.data();

                if (docData.SopUploadFile || docData?.SopUploadFileArray) {
                    const files = Array.isArray(docData.SopUploadFileArray)
                        ? docData.SopUploadFileArray
                        : Array.isArray(docData.SopUploadFile)
                        ? docData.SopUploadFile
                        : docData.SopUploadFile && Object.keys(docData.SopUploadFile).length
                        ? [docData.SopUploadFile]
                        : [];
                    
                    files.forEach((ele) => {
                        let newObject;
                        try {
                            newObject = {
                                id: ele.id ? ele.id : `${Math.random().toString(36).substr(2, 9)}`,
                                fileName: ele.imageName,
                                file: this.documentName(ele.imageName),
                                name: ele.imageUrl,
                                from: 'database',
                                title: ele.title ? ele.title : this.documentName(ele.imageName)
                            };
                        } catch (error) {
                            console.error(error)
                            return;
                        }
                        const existingIndex = this.commonArrayFOrSOP.findIndex(item => item.id === ele.id);

                        if (existingIndex > -1) {
                            this.commonArrayFOrSOP[existingIndex] = {...this.commonArrayFOrSOP[existingIndex], ...newObject};
                        } else {
                            this.commonArrayFOrSOP.push(newObject);
                        }

                    });
                } else {
                    this.commonArrayFOrSOP = [];
                }
                if(docData.RoughSopUploadFile && Object.keys(docData.RoughSopUploadFile).length > 0) {
                    this.attachemntArray = [{
                        fileName: docData.RoughSopUploadFile.imageName,
                        file: this.documentName(docData.RoughSopUploadFile.imageName),
                        name: docData.RoughSopUploadFile.imageUrl,
                        from: 'database'
                    }];
                } else {
                    this.attachemntArray = [];
                }
        });
    },
    methods: {
        generateUniqueId() {
            return '' + Math.random().toString(36).substr(2, 9);
        },
        async fileUploadFunctionHandler(uploadType) {
            let newArray = uploadType === 'roughSop' ? this.commonArrayFOr : this.commonArrayFOrSOP.filter(e => e.from !== 'database');
            let previousArray = this.commonArrayFOrSOP.filter(e => e.from === 'database').map(item => ({
                imageUrl: item.name,
                imageName: item.fileName,
                title: item.title,
                id: item.id
            }));

            try {
                if(newArray.length == 0){
                    this.$root.$emit('showToastMessage', 'Please select files.', 'danger');
                    return;
                }
                if(uploadType !== 'roughSop') {
                    this.isSpinner2 = true;
                }

                let count = 0;
                const uploadedFilesData = [];
                const uploadedUrls = [];

                const uploadFun = async () => {
                    if (count >= newArray.length) {
                        if(uploadType !== 'roughSop') {
                            this.isSpinner2 = false;
                        }
                        const updateObject = {
                            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                        };

                        let key = uploadType === 'roughSop' ? 'RoughSopUploadFile' : 'SopUploadFileArray';
                        updateObject[key] = [...uploadedFilesData, ...previousArray];

                        updateQuery.updateRootCollectionDataByDocId(
                            dbCollections.USERS,
                            this.$route.params.id,
                            updateObject,
                            () => {
                                this.$root.$emit('showToastMessage', 'Files uploaded and database updated successfully.', 'success');
                            }
                        );

                        return;
                    }

                    const fileObject = newArray[count];
                    const fileName = fileObject.name;
                    const myRnId = parseInt(Date.now() * Math.random());
                    const uniqueFileName = myRnId + "_" + fileName;
                    this.$set(this.spinnerStatus, fileObject.id, true);

                    uploadImageFile.uploadImageFileWithoutUniqueString(
                        `${uploadType === 'roughSop' ? 'StudentRoughSopFiles' : 'StudentSopFiles'}`,
                        uniqueFileName,
                        fileObject.files,
                        (res) => {
                            let uploadedFileData = {
                                id: fileObject.id,
                                title: fileObject.title,
                                ...res
                            };
                            uploadedFilesData.push(uploadedFileData);
                            uploadedUrls.push(res.imageUrl);
                            let updateObj = {
                                id: fileObject.id,
                                fileName: res.imageName,
                                file: this.documentName(res.imageName),
                                name: res.imageUrl,
                                from: 'database',
                                title: fileObject.title
                            }
                            let findIndex = this.commonArrayFOrSOP.findIndex((e)=>{
                                return e.id === fileObject.id;
                            })
                            if(findIndex!== -1){
                                this.commonArrayFOrSOP.splice(findIndex, 1, updateObj);
                            }else{
                                this.commonArrayFOrSOP.push(updateObj);
                            }
                            this.$set(this.spinnerStatus, fileObject.id, false);

                            count++;
                            uploadFun();
                        }
                    );
                };

                uploadFun();

            } catch (error) {
                console.error("Error uploading files:", error);
                this.$root.$emit('showToastMessage', 'An error occurred while uploading files.', 'danger');
            }
        },

        handleFileUpload() {
            const maxFileSizeMb = 10;
            let allFilesValid = true;
            if (this.sopFile.size >= maxFileSizeMb * 1024 * 1024) {
                allFilesValid = false;
            }

            const allowedTypes = [
                'image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 
                'application/doc', 'text/plain','application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ];

            if (this.sopFile.type && !allowedTypes.includes(this.sopFile.type)) {
                this.$root.$emit('showToastMessage', 'File allowed only: Text, JPEG, JPG, PNG, PDF, DOC, DOCX [Upload Again]', 'danger');
                allFilesValid = false;
                document.getElementById("uploadFileArray1").value = null;   
                return;
            }
            if (!allFilesValid) {
                document.getElementById("uploadFileArray1").value = null;
                this.$root.$emit('showToastMessage', 'Each file size should be less than 10 MB.', 'danger');
                return;
            }
            this.$refs.sopForm.validate().then(async (success) => {
                if (success) {
                    const newSop = {
                        id: this.generateUniqueId(),
                        'files': this.sopFile,
                        'file': this.sopFile.name,
                        'fileName': this.sopFile.name,
                        'name': this.sopFile.name,
                        'from': 'local',
                        'title': this.sopTitle
                    };
                    this.commonArrayFOrSOP.push(newSop);
                    this.$bvModal.hide('add-sop-modal');
                    setTimeout(() => {
                        this.resetData();
                    }, 100);
                }
            });
        },

        removeDoc() {
            const isRough = this.fileType === 'rough';
            const targetArray = isRough ? this.attachemntArray : this.attachemntArraySOP;
            const targetCommonArray = isRough ? this.commonArrayFOr : this.commonArrayFOrSOP;
            const fileIdName = this.fileNameRoughSop;

            this.$set(this.spinnerStatus, this.fileId, true);

            const collectionKey = isRough ? 'RoughSopUploadFile' : 'SopUploadFileArray';
            const storageFolder = isRough ? 'StudentRoughSopFiles' : 'StudentSopFiles';

            if (this.fromWhere === 'database') {
                uploadImageFile.removeImage(
                    storageFolder,
                    fileIdName,
                    (callBack) => {
                        let updatedFiles;
                        if(!isRough) {
                            const index = targetCommonArray.findIndex((file) => file.id === this.fileId);
                            if (index !== -1) {
                                targetCommonArray.splice(index, 1);
                            }
    
                            updatedFiles = targetCommonArray.map(item => ({
                                id: item.id,
                                imageUrl: item.name,
                                imageName: item.fileName,
                                title: item.title || ''
                            }));
                        } else {
                            updatedFiles = [];
                        }

                        let updateObject = {
                            updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                        };
                        updateObject[collectionKey] = updatedFiles;
                        updateQuery.updateRootCollectionDataByDocId(
                            dbCollections.USERS,
                            this.$route.params.id,
                            updateObject,
                            () => {
                                this.$set(this.spinnerStatus, this.fileId, false);
                                this.fileNameRoughSop = '';
                                this.fileType = '';
                                this.fromWhere = '';
                                this.fileId = '';
                                this.$root.$emit('showToastMessage','File deleted successfully.','success');
                            }
                        );
                    }
                );
            } else {
                if(!isRough) {
                    const index = targetCommonArray.findIndex((file) => file.id === this.fileId);
                    if (index !== -1) {
                        targetCommonArray.splice(index, 1);
                    }
                } else {
                    targetArray.splice(0,1);
                }
                this.$set(this.spinnerStatus, this.fileId, false);
                this.fileNameRoughSop = '';
                this.fileType = '';
                this.fromWhere = '';
                this.fileId = ''
                this.$root.$emit('showToastMessage', 'File deleted successfully.', 'success');
            }
        },

        cancelModelHandler() {
            this.fileNameRoughSop = '';
            this.fileType = '';
            this.fromWhere = '';
            this.fileId = '';
        },
        openModel(docType, docobj) {
            if(this.isSpinner2) {
                return;
            }
            this.fileType = docType;
            this.fileNameRoughSop = docobj.fileName;
            this.fileId = docobj.id;
            this.fromWhere = docobj.from;
            this.$bvModal.show(`modal-removeDoc`);
        },
        downloadDocument(docFile,type){
            var self = this;
            let doc = docFile.name;
            if(type == 'rough'){
                this.isSpinner = true;
            } else {
                this.$set(this.spinnerStatus, docFile.id, true);
            }
            if(doc.includes('firebase')){
                var httpsReference = firebase.storage().refFromURL(doc);
                let arr = httpsReference.name.split('_');
                arr.splice(0,1);
                let fileName = arr.join("_");
                axios({
                    url: doc,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
        
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', fileName);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.$root.$emit('showToastMessage','File downloaded successfully.','success');
                    if(type == 'rough'){
                        this.isSpinner = false;
                    } else {
                        this.$set(this.spinnerStatus, docFile.id, false);
                    }
                })
                .catch(error=>{
                    console.error(error);
                    if(type == 'rough'){
                        this.isSpinner = false;
                    } else {
                        this.$set(this.spinnerStatus, docFile.id, false);
                    }
                    self.$root.$emit('showToastMessage',error.message,'danger')
                })
            }
            else{
                var fileURL = window.URL.createObjectURL(new Blob([docFile.files]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', docFile.fileName);
                document.body.appendChild(fileLink);
                fileLink.click();
                if(type == 'rough'){
                    this.isSpinner = false;
                } else {
                    this.$set(this.spinnerStatus, docFile.id, false);
                }
            }
        },
        resetData() {
            this.sopTitle = '';
            this.sopFile = [];
        },
        documentName(doc) {
            let name = doc;
            let arr = name.split("_");
            arr.splice(0, 1);
            return arr.join("");
        },
    },
}
</script>
<style scoped>
span.deleteBtn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #0d4ea0;
    word-break: break-all;
}
span.delete-pdf{
    padding: 0px 8px;
}
.pointerClassCss{
    cursor: pointer !important;
}
.upload_wrapper{
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
}
.upload_save {
    width: 85%;
}
.upload_save_called{
    width: 15%;
    position: relative;
    top: 3px;
}
.spinner-border-data {
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: text-bottom;
}
</style>
<style>
.sop_modal_filetype .custom-file-label::after {
    content: "Upload File" !important;
    text-transform: capitalize;
}
.sop_modal_filetype .custom-file-label {
    border-color:#efefef !important;
}
</style>
